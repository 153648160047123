export default {
  palette: {
    primary: {
      0: '#d81ac3',
      1: '#110F1D',
      2: '#6E135A',
      3: '#59110b',
      4: '#510404',
      5: '#007079',
      6: '#df463a',
      7: '#3e0c66',
      8: '#72253d',
      9: '#FFA400',
      10: '#a07e00',
      11: '#caa248',
      12: '#5e1aa4',
      13: '#c9ab5d',
      14: '#ebb957',
      15: '#e8d19e',
      16: '#0088ff',
      17: '#6200EE',
      18: '#A01AC9',
      19: '#5d2a04',
      20: '#282c34',
      21: '#61dafb',
      22: '#004e7f',
      23: '#9c1f1f',
      24: '#d4b26473',
      25: '#ecc534',
      26: '#efd17c',
      27: '#ffffff',
      28: '#085604',
      29: 'transparent',
      30: '#626262',
      31: '#bb1daa',
      32: 'rgba(255, 255, 255, 0.05)',
      33: '#000',
      34: '#fff'
    },
    secondary: {
      0: '#085604',
      1: '#085604',
      2: '#23093a',
      3: '#2A0148',
      4: '#24063d',
      5: '#2b054e',
      6: '#B50000',
      7: '#511a74',
      8: '#5f0ba7',
      9: '#9850b2',
      10: '#9F4ED1',
      11: '#d0a94f',
      12: '#007079',
      13: '#00ECFF',
      14: '#ac43d8',
      15: '#cba650',
      16: '#ae7ac7',
      17: '#a083c6',
      18: '#9E81A7',
      19: '#9273aa',
      20: '#614275',
      21: '#200a3a',
      22: '#40006b',
      23: '#b368ff',
      24: '#caac67',
      25: '#c26bde',
      26: '#671d8f',
      27: '#007079',
      28: 'rgba(122, 21, 189, 0.4)',
      29: 'rgba(87, 23, 184, 0.8)',
      30: '#a062d8',
      31: '#1f063d',
      32: '#022500',
      33: '#bb0000',
      34: '#730000',
      35: 'rgba(255, 0, 42, 0.3)',
      36: 'rgba(223, 70, 58, 0.9)',
      37: '#ad8b4c',
      38: '#edd8ae',
      39: '#41fff4',
      40: '#76D671',
      41: '#003745',
      42: '#253637',
      43: '#fff',
      44: '#f5f2f2',
      45: '#695038'
    },
    common: {
      0: 'black',
      1: '#ffffff',
      2: '#202020',
      3: '#222222',
      4: '#333333',
      5: '#666666',
      6: '#ffffff',
      7: '#989898',
      8: '#999999',
      9: '#A0A0A0',
      10: '#AFABB1',
      11: '#bbbbbb',
      12: '#bdbdbd',
      13: '#f4f4f4',
      14: '#dddddd',
      15: '#eeeeee',
      16: '#111111',
      17: '#aaaaaa',
      18: '#cccccc',
      19: '#fafafa',
      20: '#eeefec',
      21: '#303030',
      22: '#d2cfcf',
      23: 'rgba(0,0,0,0.8)',
      24: 'rgba(0,0,0,0.75)',
      25: 'rgba(0, 0, 0, 0.35)',
      26: 'rgba(0, 0, 0, 0.5)',
      27: 'rgba(187, 187, 187, 0.35)',
      28: 'rgba(0, 0, 0, 0.45)',
      29: 'rgba(255, 255, 255, 0.6)',
      30: 'rgba(0,0,0,0)',
      31: 'rgba(0,0,0,0.65)',
      32: 'rgba(175, 171, 177, 1)',
      33: 'rgba(255, 255, 255, 0.1)',
      34: 'rgba(255, 255, 255, 0.4)',
      35: 'rgba(0, 0, 0, 0.1)',
      36: 'rgba(0, 0, 0, 0.6)',
      37: '#ffffff',
      38: '#383736',
      39: '#3e3029',
      40: 'rgba(175,171,177,1)',
      41: '#ff343b',
      42: '#6B3E16'
    },
    error: {
      0: '#CB4038',
      1: '#FF5042'
    },
    success: {
      0: '#36C41E',
      1: '#36FF8E',
      3: '#085604'
    },
    warning: {
      0: '#EDC309',
      1: '#FFE868'
    },
    stroke: {
      0: '#085604',
      1: '#3862AD',
      2: 'transparent',
      3: '#1D7EBB',
      4: '#D56E00',
      5: '#626262',
      6: '#BB1DAA',
      7: '#730000',
      8: '#2C167C',
      9: '#7707AC'
    },
    scrollbar: {
      0: '#003754',
      1: '#007079',
      2: '#ffffff'
    }
  }
};